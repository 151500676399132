<template>
  <div style="width: 100%;height: 100%;background-color: #F5F8FA;">
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>

    <div class="all" v-loading="videoLoading">
      <div class="container">
        <div class="video-name">
          {{ this.courseName }}
        </div>
        <div class="video-tab">
          <div class="video-left" style="width: 100%;" id="dplayer">
            <!-- <d-player ref="player" id="player" style="height: 100%;" :options="options" @play="videoPlayBegin()"
              @pause="videoStop()" @fullscreen="videoFullscreen()" @fullscreen_cancel="deleteVideoTab()">
            </d-player> -->
            <!-- fullscreen:全屏事件,fullscreen_cancel:退出全屏 -->
            <!-- <video id="myVideo" style="height: 100%;width: 100%;" class="video-js vjs-default-skin vjs-big-play-centered"
              controls preload="auto" width="1080" height="708" data-setup='{}'>
              <source :src="videoSrc" type="application/x-mpegURL">
            </video> -->
            <video poster="#" ref="video" style="height: 100%;width: 100%;" id="myVideo"
              class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" data-setup='{}'>
            </video>
            <div class="progress-bar"
              style="width: 100%;height: 16px;background-color: #1371E7;position: relative;bottom: 47px;opacity: 0;">
            </div>
            <div class="video-msg">
              {{ this.videoMsg }}
            </div>
          </div>
          <div class="video-right">
            <!-- <div class="video-header">
              <div class="video-title">
                <h3 style="color: black;">目录</h3>
                <span v-if="videoData.length > 0">共{{ videoData[0].creditHours }}课时 | 学习进度: {{ videoData[0].percent
                }}%</span>
              </div>
            </div> -->
            <div class="video-body">
              <el-tabs style="width: 100%;" v-model="activeName">
                <el-tab-pane name="first">
                  <span slot="label" style="font-size: 16px;font-weight: 600;">选集</span>
                  <div class="video-list">
                    <el-collapse>
                      <el-collapse-item class="collapse-item" v-for="(item, i) in videoData">
                        <template slot="title">
                          <span style="width: 80%;height: 100%;overflow: hidden;font-size: 15px;">
                            <span style="">{{ '第' + (i + 1) + '章 ' }} </span>
                            {{ item.name }}
                          </span>
                        </template>
                        <div class="collapse-item-list" v-for="(item, index) in item.sectionDTOList"
                          :style="i + '-' + index == CheckSet ? 'color: #409eff' : 'color: #303133'">
                          <span class="section" style="cursor: pointer;padding: 5px 0;"
                            @click="liClick(item.id, item.appUserCourseStudyItem.id, i, index, item)">
                            <span style="font-size: 15px;">{{ (index + 1) < 10 ? "0" + (index + 1) : (index + 1) }}.
                                </span>
                                <span style="font-size: 15px;">
                                  {{ item.name }}
                                </span>

                                <span style="color: red;font-size: 15px;">
                                  {{ item.appUserCourseStudyItem.status == 0 ? "（未完成）" : "" }}
                                </span>
                                <span>
                                  {{ item.appUserCourseStudyItem.status == 0 ? "" : "（已完成）" }}
                                </span>
                            </span>
                            <span>
                            </span>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-tab-pane>
                <el-tab-pane name="second">
                  <span slot="label" style="font-size: 16px;font-weight: bold;">更换课程</span>
                  <div class="video-list" v-loading="courseListLoading">
                    <div class="video-list-courseitem" @click="courseClick(item)" v-for="(item, index) in courseData"
                      :style="item.courseName == courseName ? 'color: #409eff;' : ''">
                      <div>
                        <span style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                          {{ index + 1 }} .
                          {{ item.courseName }}
                        </span>
                      </div>
                      <div style="display: flex;justify-content: space-between;padding: 0 17px;">
                        <span>
                          {{ item.status == 0 ? "(未完成)" : "(已完成)" }}
                        </span>
                        <span>
                          {{ item.creditHours }}学时
                        </span>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <!-- <div style="width: 1200px;height: 60px; margin: 0 auto;border: 1px solid rgb(219, 219, 219);border-radius: 10px;background-color: #fff;" class="video-msg">

        </div> -->
      </div>
      <!-- <div class="container">
          <span class="title-top">{{sectionData.name}}</span>
      </div> -->
    </div>

    <!-- bottom-->
    <!-- <div class="bottom">
      <div class="bottom-content">
        <div>Copyright 2023 • 苏州市电子信息技师学院</div>
        <div class="bottom-right">
          <a style="color: #fff;" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备11034322号-2</a>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
// import Video from "@/components/video/video"
import topNav from "@/components/nav/nav.vue"
import { httpGet, httpNPGet, httpPost, httpNPPost } from "@/utils/httpRequest";
import videojs from "video.js"


// var interval;
export default {
  name: "",
  data() {
    return {
      // 选课列表显示
      drawer: false,
      // 选课列表按钮显示
      drawerBtn: false,
      // 课程名称
      courseName: '',
      // 切换选集和课程
      activeName: "first",
      // 定时器
      timer: "",
      pageIndex: 3,
      videoData: [],
      courseData: [],
      sectionData: {},
      navList: ["首页", "选课中心", "帮助中心", "个人中心", "联系我们"],
      active: 0,
      translateX: 700,
      lineWidth: 32,
      imgUrl: ["../assets/be-首屏.png", "../assets/be-首屏.png"],
      // 浮动框
      xPos: document.body.clientWidth - 500,
      yPos: 100,
      xin: true,
      yin: true,
      step: 1,
      delay: 20,
      height: 0,
      heightSet: 0,
      widthSet: 0,
      yon: 0,
      xon: 0,
      pause: true,
      promptShow: true,
      // navStatus:false,
      //  -------
      value: true,
      options: {
        container: document.getElementById("dplayer"), //播放器容器
        autoplay: true, //视频自动播放
        mutex: false, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
        theme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
        loop: false, // 是否自动循环
        lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
        // screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
        hotkey: false, // 是否支持热键，调节音量，播放，暂停等
        preload: "auto", // 自动预加载
        volume: 0.7, // 初始化音量
        playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2, 3], //可选的播放速度，可自定义
        // logo:
        //     "https://qczh-1252727916.cos.ap-nanjing.myqcloud.com/pic/273658f508d04d488414fd2b84c9f923.png", // 在视频左上角上打一个logo
        video: {
          url: '', // 播放视频的路径
          // quality: [
          //   // 设置多个质量的视频
          //   {
          //     name: "高清",
          //     url:
          //       "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
          //     type: "auto" // 'auto', 'hls', 'flv', 'dash', 'webtorrent', 'normal' 或 其他自定义类型
          //   },
          //   {
          //     name: "标清",
          //     url:
          //       "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
          //     type: "auto"
          //   }
          // ],
          defaultQuality: 0, // 默认是高清
          pic:
            "", // 视频封面图片
          thumbnails:
            "" // 进度条上的缩略图,需要通过dplayer-thumbnails来生成
        },
        // subtitle: {
        //   //字幕
        //   url: "", //字幕网址
        //   color: "#fff", //字幕颜色
        //   fontSize: "20px",
        //   bottom: "40px"
        // },
        contextmenu: [
          //  自定义上下文菜单
          // 右键菜单
          {
            text: "b站",
            link: "https://www.bilibili.com"
          },
          {
            text: "选项二",
            click: player => {
              // console.log(player);
            }
          }
        ],
        highlight: [
          //进度条上的自定义时间标记
          // 进度条时间点高亮
          // {
          //   text: "10M",
          //   time: 6
          // },
          // {
          //   text: "20M",
          //   time: 12
          // }
        ],
        studyItemId: "",
      },
      rightIcon: "<",
      // 侧边tab显示状态
      drawer: false,
      videoTabAddress: "",
      videoList: [],
      CheckSet: "0-0",
      videoSrc: '',
      // 视频播放位置
      currentTime: '',
      videoUrl: '',
      videoLoading: '',
      courseListLoading: '',
      i: '',
      index: '',
      videoMsg: ''
    };
  },
  components: {
    // myVideo:Video
    topNav
  },
  created() {

  },
  // beforeDestroy() {
  //   clearInterval(timer);
  // },
  mounted() {
    // this.addVideoTab()
    this.courseName = this.$route.query.courseName;
    this.getSubjects()
    this.$nextTick(() => {
      // videojs 简单使用
      this.myVideo = videojs('myVideo', {
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
      })
      this.myVideo.on('fullscreenchange', (e) => {
        if (document.fullscreenElement) {
          // console.log('进入全屏');
          this.addVideoTab()
        } else {
          // console.log('退出全屏');
          this.deleteVideoTab()
        }
      })
      // console.log(this.myVideo);
      // 获取课程视频数据列表
      this.getSubVideo(this.$route.query.courseId);
      // document.getElementsByClassName("dplayer-controller")[0].style.boxSizing = "border-box"
      // console.log(this.$route)
    });

    this.$refs.video.addEventListener("pause", this.videoStop)
    this.$refs.video.addEventListener("play", this.videoPlayBegin)
    this.$refs.video.addEventListener("resize", this.screenChange)
    // this.$refs.video.addEventListener("fullscreenchange", this.screenChange)
    // window.addEventListener("onresize", this.videoFullscreen)

  },
  methods: {
    // 全屏监听
    screenChange() {
      // console.log("全屏")
    },
    // 获取课程列表
    getSubjects() {
      this.courseListLoading = true
      httpGet("/app/app-user-course-study/list",
        {
          status: 0
        }
        , (res) => {
          // console.log(res.data)
          this.courseData = res.data
          // this.allCourseList = res.data
          this.courseListLoading = false
        }, (err) => {
          // console.log(err)
          throw err
        })
    },
    // 视频播放
    changeVideo(vdoSrc) {
      // console.log(vdoSrc);

      if (/\.m3u8$/.test(vdoSrc)) { //判断视频源是否是m3u8的格式
        this.myVideo.src({
          src: vdoSrc,
          type: 'application/x-mpegURL' //在重新添加视频源的时候需要给新的type的值
        })
      } else {
        this.myVideo.src(vdoSrc)
      }
      this.myVideo.currentTime(this.currentTime);
      this.myVideo.load();
      this.myVideo.play();
    },
    // 更换课程
    courseClick(data) {
      this.courseName = data.courseName

      this.getSubVideo(data.courseId)
    },
    // 节点添加视屏TAB
    addVideoTab() {
      // 视频播放跟节点
      // this.videoTabAddress = this.$refs.video
      // document.documentElement.requestFullscreen()
      // console.log(document.fullscreenElement)
      // 视频播放跟节点
      let video = document.fullscreenElement
      this.videoTabAddress = document.fullscreenElement
      // 按钮节点
      let tabBtn = document.createElement('div');
      tabBtn.className = "right-body"
      tabBtn.style = "background-color: #303030;color: #ffffff70;right: 0px;width: 30px;line-height: 60px;cursor: pointer;font-size: 22px;position: relative;top: 460px;float: left;text-align: center;border-radius: 10px 0px 0px 10px;"
      tabBtn.onclick = function () {
        // console.log(tabBox.style.right)
        // let dplayer = document.getElementsByClassName("dplayer-controller")[0]
        dplayer.style.boxSizing = "border-box"
        let rightBody = document.getElementsByClassName("right-body")[0]
        let videoList = document.getElementsByClassName("video-list")
        // console.log(document.documentElement.clientWidth)
        if (tabBox.style.right == "-370px") {
          tabBox.style.right = "0px"
          tabBtn.textContent = ">"
          rightBody.style.backgroundColor = "#30303050"
          // console.log(videoList)
          videoList[0].style.height = "930px";
          videoList[1].style.height = "930px";
          dplayer.style.width = document.documentElement.clientWidth - 370 + "px"

        } else if (tabBox.style.right == "0px") {
          tabBox.style.right = "-370px"
          tabBtn.textContent = "<"
          rightBody.style.backgroundColor = "#30303050"
          // console.log(rightBody.backgroundColor)
          videoList[0].style.height = "390px";
          videoList[1].style.height = "390px";
          dplayer.style.width = document.documentElement.clientWidth + "px"
        }
      }
      tabBtn.textContent = this.rightIcon
      // console.log(tabBtn)
      // 主要侧边栏节点
      let tabMain = document.createElement("div");
      tabMain.style = "background-color: #ffffff;width: 370px;height: 100%;float: left;"
      let videoRight = document.getElementsByClassName("video-right")[0]
      // console.log(videoRight)
      tabMain.appendChild(videoRight)
      // Tab外层节点
      let tabBox = document.createElement("div");
      tabBox.id = "tab-box"
      tabBox.style = "position: fixed; top: 0px;right: -370px;width: 400px;height: calc(100% - 30px);z-index: 1;transition-property: transform;transition-duration: 1s;"
      tabBox.appendChild(tabBtn);
      tabBox.appendChild(tabMain);
      // 把Tab添加到根节点
      video.appendChild(tabBox);
    },
    // 删除Tab 视频全屏退出事件事件
    deleteVideoTab() {
      let videoRight = document.getElementsByClassName("video-right")[0]
      let videoTab = document.getElementsByClassName("video-tab")[0]
      // let dplayer = document.getElementsByClassName("dplayer-controller")[0]
      let videoList = document.getElementsByClassName("video-list")
      videoList[0].style.height = "390px";
      videoList[1].style.height = "390px";
      dplayer.style.width = "100%"
      videoTab.appendChild(videoRight)
      document.getElementById("tab-box").remove()
    },
    // 小节点击事件
    liClick(sectionId, studyItemId, i, index, item) {
      // console.log(sectionId, item.appUserCourseStudyItem.id)
      this.currentTime = sectionId, item.appUserCourseStudyItem.playTime
      this.CheckSet = i + "-" + index
      // 赋值当前小节索引
      this.i = i
      this.index = index
      this.getSectionsMsg(sectionId, studyItemId)
    },
    // 根据小节获取当前课程具体数据     
    //              视频ID,学习记录ID ,章节index，小节index
    getSectionsMsg(sectionId, studyItemId) {
      this.studyItemId = studyItemId
      // console.log(studyItemId)
      httpGet("/courseSection/pay-url",
        {
          courseId: this.$route.query.courseId,
          sectionId: sectionId,
          studyItemId: studyItemId,
        }, (res) => {
          this.sectionData = res.data
          this.videoUrl = res.data.url
          this.changeVideo(this.$store.getters.vodDomainName + res.data.url);
        }, (err) => {
          // console.log(err)
          throw err
        }
      )
    },
    // 根据课程ID获取视频数据
    getSubVideo(courseId) {
      // if (this.$route.query.videoType == "试听") {
      //   studyItemList = "/course-info/msg?courseId="
      // } else {
      //   studyItemList = "/course-info/msg?courseId="
      // }
      this.videoLoading = true
      httpNPGet("/course-info/msg?courseId=" + courseId,
        (res) => {
          this.videoData = res.data.courseChapterDTOList
          let studyItemId = this.videoData[0].sectionDTOList[0].appUserCourseStudyItem.id
          this.currentTime = this.videoData[0].sectionDTOList[0].appUserCourseStudyItem.playTime
          this.studyItemId = studyItemId
          // 赋值当前小节索引
          this.i = 0
          this.index = 0
          let sectionId = this.videoData[0].sectionDTOList[0].id
          this.getSectionsMsg(sectionId, studyItemId)
          // this.videoPlayBegin()
          // console.log(this.videoData)
          this.videoLoading = false
        },
        (err) => {
          if (err.code == 500) {
            if (err.msg == "token异常，请重新登录") {
              localStorage.clear();
              if (!localStorage.getItem("userId")) {
                this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login")
              }
            }
          }
          // console.log(err)
          throw err
        }
      )
    },
    // 上传视频播放时间开始
    videoPlayBegin() {
      clearInterval(this.timer);
      // console.log(this.studyItemId)
      if (this.studyItemId) {
        httpPost("/app/app-user-course-study-item/check", {
          playTime: this.$refs.video.currentTime,
          studyItemId: this.studyItemId,
          duration: 10,
          status: 0,
        })
        this.timer = setInterval(this.videoPlay, 10 * 1000);
      }
    },
    // 上传视频播放时间停止
    videoPlayEnd() {
      clearInterval(this.timer);
    },
    // 上传视频当前播放时间数据
    videoPlay() {
      // console.log(this.studyItemId)
      // if (!this.$refs.player.dp.video.paused) {
      // console.log(parseInt(this.$refs.video.currentTime))
      if (this.$refs.video) {

        httpPost("/app/app-user-course-study-item/check",
          {
            playTime: this.$refs.video.currentTime,
            studyItemId: this.studyItemId,
            duration: 10,
            status: 1,
          }, (res) => {
            // console.log(res)
          }, (err) => {
            // this.$refs.player.dp.video.pause()
            // console.log(err)
            throw err
          })
      }

      // } else {
      //   this.videoPlayEnd()
      // }
    },
    // 视频暂停事件
    videoStop(studyItemId) {
      // 视频播放完成
      // console.log(this.$refs.video.currentTime)
      // console.log(this.$refs.video.duration)
      if (this.$refs.video) {
        if (this.$refs.video.currentTime == this.$refs.video.duration) {
          // console.log("视频播放完成")
          httpPost("/app/app-user-course-study-item/check", {
            playTime: this.$refs.video.currentTime,
            studyItemId: this.studyItemId,
            duration: 10,
            status: 1,
          }, (res) => {
            httpPost("/app/app-user-course-study-item/check",
              {
                playTime: this.$refs.video.currentTime,
                studyItemId: this.studyItemId,
                duration: 10,
                status: 2,
              }, (res) => {
                this.$message.success("恭喜学完该课程")
                // console.log(this.i, this.index)
                this.videoData[this.i].sectionDTOList[this.index].appUserCourseStudyItem.status = 1
                // this.$alert('恭喜学完该课程，可更换课程进行学习', '观看完成', {
                //     confirmButtonText: '确定',
                //     callback: action => {
                //       // this.myVideo.currentTime(err.data.studyItem.totalTime);
                //     }
                //   });


                // 下一集
                // if (this.value == true) {
                //   let i = parseInt(this.CheckSet.split("-")[0])
                //   console.log(this.videoData)
                //   let index = parseInt(this.CheckSet.split("-")[1]) + 1
                //   console.log(i)
                //   console.log(index)
                //   if (index >= this.videoData[i].sections.length) {
                //     i = i + 1;
                //     index = 0
                //   }
                //   if (i >= this.videoData.length) {
                //     i = 0;
                //     let index = 0
                //   }
                //   console.log(i)
                //   console.log(index)
                //   console.log(this.videoData[i].sections[index].id)
                //   let sectionId = this.videoData[i].sections[index].id
                //   let studyItemId = this.videoData[i].sections[index].studyItemId
                //   this.liClick(sectionId, studyItemId, i, index)
                // }
              }, (err) => {
                // console.log(err.data)
                this.$alert('课程未学完，观看过程禁止拖动进度条，请重新观看', '观看时间异常 ', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.myVideo.currentTime(err.data.studyItem.totalTime);
                  }
                });
                // throw err
              })
          })
        } else {
          // console.log("暂停播放")
          httpPost("/app/app-user-course-study-item/check", {
            playTime: this.$refs.video.currentTime,
            studyItemId: this.studyItemId,
            duration: 10,
            status: 1,
          }, (res) => {
            this.videoPlayEnd()
          })
        }
      }


    },
    // 视屏全屏
    videoFullscreen() {
      this.addVideoTab()
      // 添加一个遮挡住进度条不让快进
    },
    //鼠标移入效果
    clearFdAd() {
      clearInterval(interval);
      document.getElementsByClassName('prompt-img')[0].style.display = 'none';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'block';
    },
    //鼠标移出效果
    starFdAd() {
      interval = setInterval(this.changePos, this.delay);
      document.getElementsByClassName('prompt-img')[0].style.display = 'block';
      document.getElementsByClassName('prompt-hover')[0].style.display = 'none';
    },
    /**
     * 点击切换标题
     * @param {number} index 菜单序列(点击的第index个)
     */
    changeTitle(index) {
      this.active = index;

      // console.log('===========', index)
      this.setUnderLine();

      if (index === 4) {
        this.$router.push = "./videoplayback/videocatalog.vue";
      }
    },

  },
  // 生命周期组件销毁
  beforeDestroy() {
    location.reload()
    if (this.timer) {
      this.videoPlayEnd();
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 58px;
  height: 58px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  height: 40px;
  line-height: 40px;
  background-color: #303030;
  color: aliceblue;
  font-size: 14px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  background-color: #303030;
  color: aliceblue;
}

.header-top-right,
.header-top-right>div {
  display: flex;
  align-items: center;
}

.header-top-right img {
  width: 16px;
  height: 16px;
  margin: 0 10px;
}

.nav {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  z-index: 999;
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #fff !important;
}

.nav-f {
  width: 100%;
  background: #fff;
  z-index: 999;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #333 !important;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
}

.nav1 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-center {
  width: 600px;
}

.nav-center .nav-list {
  /* margin: 0 auto;
  max-width: 600px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 78px;
}

.nav-center .nav-list>div {
  width: 600px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  text-align: center;
  border-right: solid 0.2px #d0d0d0;
}

.nav-center .nav-list>div:last-child {
  border: none;
}

.nav-center .nav-list .nav-list--active {
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  font-weight: bold;

  /*transition: all 0.3s;*/
  /*background-color: #FFE53B;*/
  /*background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);*/
}

.nav-line {
  margin: 0 auto;
}

.nav-line>div {
  /*width: 32px;*/
  height: 4px;
  background-color: #1371E7;
  /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
  border-radius: 2px;
  transition-duration: 0.3s;
  position: relative;
  top: 0px;
}

.nav-center-btn {
  /* height: 40px; */
  width: 122px;
  height: 40px;
  background: #1371e7;
  border-radius: 4px 4px 4px 4px;
  border: 0;
  opacity: 1;
  font-size: 16px;
  cursor: pointer;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.nav-left {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 80px;
  /* line-height: 80px; */
}

.nav-left h2 {
  font-size: 24px;
  font-family: Roboto-SemiBold, Roboto;
  font-weight: 600;
  line-height: 76px;
  letter-spacing: 2px;
}

.nav-left span {
  width: 1px;
  height: 26px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  margin: 0 15px;
  opacity: 0.2;
}

//================================

//.all {
//  background-color: #F5F8FA;
//}
.video-name {
  text-align: left;
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

.video-tab {
  //position: absolute;
  // padding-top: 10px;
  margin-top: 20px;
  padding-bottom: 25px;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  font-family: Helvetica Neue-Regular, Helvetica Neue;
  font-weight: 400;
  color: #667280;
  min-width: 1200px;

  .video-left {
    width: 839px;
    height: 550px;
    //background: #fce0e0;
    box-shadow: 0px 3px 11px 0px rgba(0, 36, 153, 0.06);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-right: 20px;
  }

  .video-right {
    // margin-left: 20px;
    background-color: #fff;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 10px;

    .video-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 12px;
      box-sizing: border-box;
      width: 343px;
      height: 80px;
      opacity: 1;
      border: 1px solid #EDEFF2;
      border-right: none;

      .video-title {
        h3 {
          text-align: left;
          font-family: Helvetica Neue-Medium, Helvetica Neue;
          font-size: 15px;
          font-weight: 500;
        }

        span {
          display: inline-block;
          padding-top: 5px;
          font-size: 12px;
          font-family: Helvetica Neue-Regular, Helvetica Neue;
          font-weight: 400;
          color: #667280;
        }
      }
    }

    .video-body {
      width: 343px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 16px;
      box-sizing: border-box;
      color: #1371E7;
      position: relative;

      .video-list {
        height: 430px;
        overflow: auto;
        // border-top: 1px solid rgb(235, 238, 245);
        font-size: 15px;

        .video-list-courseitem {
          color: #303133;
          width: 100%;
          padding: 5px 5px;
          box-sizing: border-box;
          border-radius: 5px;
          line-height: 26px;
          cursor: pointer;
        }

        .video-list-courseitem:hover {
          background-color: #5b9aff30;
          color: #409eff;
          transition: all 0.6s;
        }
      }

      .collapse-item {
        line-height: none;
        font-size: 15px;

        .collapse-item-list {
          max-width: 300px;
          display: flex;
          margin-left: 18px;
          justify-content: flex-start;
        }
      }

      .collapse-item:hover {
        background-color: #1371E7;

        .section:hover {
          color: #409eff;
        }
      }

      .video-item {
        width: 311px;
        height: 54px;
        display: flex;
        align-items: center;
        background: #F5F8FA;
        margin-bottom: 9px;
        border-radius: 2px 2px 2px 2px;

        .video-item-num {
          width: 40px;
          height: 40px;
          //display: block;
          text-align: center;
          font-size: 12px;
          font-family: Helvetica Neue-Regular, Helvetica Neue;
          font-weight: 400;
          line-height: 40px;
        }

        .video-item-right {
          display: flex;
          flex-direction: column;
          font-size: 13px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;

          .video-item-time {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            padding-top: 4px;
            color: #999999;
          }
        }
      }
    }

  }

}

.title {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  width: 800px;
  height: 68px;
  font-weight: bold;

  //line-height: 34px;
  span {
    font-size: 16px;
    font-family: Helvetica Neue-Regular, Helvetica Neue;
    font-weight: 400;
    color: #444444;
    //line-height: 18px;
  }

  .title-top {
    font-size: 24px;
    padding-bottom: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #14171A;
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/home/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}

.right-body {
  // width: 30px;
  // height: 60px;
  // text-align: center;
  // border-radius: 10px 0 0px 10px;
  color: #c3c3c3;
  // cursor: pointer;
}

::backdrop {
  background: yellowgreen;
}

::v-deep .el-collapse {
  border: none;
}

::v-deep .el-collapse-item__header {
  border: none;
}

::v-deep .el-collapse-item__wrap {
  border: none;
}

::v-deep .el-collapse-item__header:hover{
  // background-color: #c8ddff30;
  // background-color: #e1f0ff;
  transition: all 0.2s;
  color: #409eff;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
}

::v-deep .el-collapse-item__content:hover {
  // background-color: #e1f0ff;
  
  transition: all 0.2s;

  color: #409eff;
}</style>